var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"title":"STMS","link":{
					name: 'STMS',
					icon: 'fa fa-home text-success',
					path: '/stms',
				}}}),_c('sidebar-item',{attrs:{"title":"TMS","link":{
					name: 'TMS',
					icon: 'fa fa-users text-white',
					path: '/tms',
				}}}),_c('sidebar-item',{attrs:{"title":"Parents","link":{
					name: 'Parents',
					icon: 'fa fa-user text-white',
					path: '/parents',
				}}}),_c('sidebar-item',{attrs:{"title":"Counseling","link":{
					name: 'Counseling',
					icon: 'fa fa-list-alt text-success',
					path: '/counseling',
				}}}),_c('sidebar-item',{attrs:{"title":"Demo Class","link":{
					name: 'Demo Class',
					icon: 'fa fa-binoculars text-success',
					path: '/demo-class',
				}}}),_c('sidebar-item',{attrs:{"title":"Subjects","link":{
					name: 'Subjects',
					icon: 'fa fa-cubes text-success',
					path: '/subjects',
				}}}),_c('sidebar-item',{attrs:{"title":"Admins","link":{
					name: 'Admins',
					icon: 'fa fa-user text-white',
					path: '/users',
				}}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('loader'),_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }