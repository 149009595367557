<template>
<base-nav class="navbar-top bg-white" id="navbar-main" :show-toggle-button="false" expand>
	<!-- <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
		<div class="form-group mb-0">
			<base-input placeholder="Search" class="input-group-alternative" v-model="searchQuery"
				@focus="showSearchMenu = true" addon-right-icon="fas fa-search" v-click-outside="closeSearch">
				<template v-if="showSearchMenu" v-slot:infoBlock>
					<nav class="position-absolute p-2 bg-white w-100 rounded suggestions-box">
						<div class="text-left position-absolute pt-1 pl-1">
							Search results
						</div>
						<div class="text-right">
							<div class="btn mb-1 p-1 pl-2 pr-2" @click.stop="closeSearch">x</div>
						</div>
						<div class="search-scroller">
							<div v-for="(suggestion, index) in filteredSuggestions" :key="index" @click.stop="goToPage(suggestion.toPage)"
								class="p-2 mb-2 rounded suggestion-box">
								<strong>{{ suggestion.title }}</strong>
								<br>
								{{ suggestion.subTitle }}
							</div>
						</div>
					</nav>
				</template>
			</base-input>
		</div>
	</form> -->
	<ul class="navbar-nav align-items-center d-none d-md-flex">
		<li><a   class="nav-link nav-link-icon"  role="button"  aria-haspopup="true" aria-expanded="false" @click.prevent="notificationToggle">
		<i  class="ni ni-bell-55" :class=" notificationsCount>0	? 'text-danger':''"><badge type="white" class="text-success">{{notificationsCount}}</badge></i>
	</a></li>
		<li class="nav-item dropdown">
			<base-dropdown class="nav-link pr-0 pl-0" position="right">
				<a slot="title" class="media align-items-center nav-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span class="avatar avatar-sm">
						<img alt="User icon" src="img/userIcon.png">
						<!-- $store.state.loginData.profilePicture -->
					</span>
					<div class=" ml-2 d-none d-lg-block mr-3">
						<span class="mb-0 text-black">{{ $store.state.profileData.data.name }}</span> <br/>
						<span class="mb-0 text-sm text-success">Admin</span>
					</div>
					<div class="media-body ml-2 d-none d-lg-block">
						<i class="fa fa-angle-down" />
					</div>
					<!-- {{ $store.state.loginData.username }} -->
				</a>

				<mini-nav></mini-nav>
			</base-dropdown>
		</li>
	</ul>
</base-nav>
</template>
<script>
import MiniNav from './MiniNav.vue';
// import NotificationPanel from './NotificationPanel.vue';

export default {
	components: {
		MiniNav,
		// NotificationPanel
	},
	data() {
		return {
			searchSuggestions: [
				{
					title: 'Dashboard',
					subTitle: 'See all info at a glance',
					keywords: 'dashboard home',
					toPage: '/dashboard'
				},
				{
					title: 'Profile',
					subTitle: 'Have a look at your profile information',
					keywords: 'profile account',
					toPage: '/profile'
				},
				{
					title: 'Contact',
					subTitle: 'Contact us',
					keywords: 'contact help support',
					toPage: '/contact'
				},
			],
			showSearchMenu: false,
			searchQuery: '',
			notificationSts:false
		};
	},
	computed: {
		notifBg(){
			return {background: '#F8F8F8', borderRadius: '10px'}
		},
		filteredSuggestions() {
			var ctx = this;
			var searchQuery = this.searchQuery.trim().toLowerCase();
            return this.searchSuggestions.filter((suggestion) => {
                return suggestion.keywords.indexOf(searchQuery) >= 0 && suggestion.toPage != ctx.$route.path;
            });
		},
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		notificationsList(){
			return this.$store.state.notificationData.notificationsList;
		},
		notificationsCount(){
			return this.notificationsList.filter(item=>item.status=='unread').length;
		},
		totalNotifCount() {
			return this.$store.state.notificationData.notifCount;
		}
    },
	methods: {
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		goToPage(page) {
			this.searchQuery = '';
			this.showSearchMenu = false;
			this.$router.push(page);
		},
		closeSearch() {
			this.showSearchMenu = false;
		},
		notificationToggle(){
			if(this.totalNotifCount>0){
			if(!this.notification){
				this.$store.dispatch('notificationToggle',!this.notificationSts)
			}else{
				this.$store.dispatch('notificationToggle',this.notificationSts)
			}
			}else{
				this.$notify({
						title: "No new Notifications",
						icon: "far fa-frown-open",
						type: "warning",
					});
			}
		},
		fetchNotifications() {
			this.$store
				.dispatch("fetchNotificationsList")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch notifications",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},

	},
	mounted(){
		this.fetchNotifications();

	}
};
</script>
<style scoped>
.suggestions-box {
	box-shadow: 0px 5px 21px 6px #a5a5a5;
	z-index: 1000;
	top: 120%;
	font-size: 14px;
}

.suggestion-box {
	background-color: #f7f7f7;
	transition: .2s background-color;
}

.suggestion-box:hover {
	background-color: #e4e4e4;
}

.search-scroller {
	overflow: auto;
	max-height: 300px;
}
</style>
