<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
		<side-bar :background-color="sidebarBackground">
			<template slot="links">
				<sidebar-item
					title="STMS"
					:link="{
						name: 'STMS',
						icon: 'fa fa-home text-success',
						path: '/stms',
					}"
				/>
				<sidebar-item
					title="TMS"
					:link="{
						name: 'TMS',
						icon: 'fa fa-users text-white',
						path: '/tms',
					}"
				/>
				<sidebar-item
					title="Parents"
					:link="{
						name: 'Parents',
						icon: 'fa fa-user text-white',
						path: '/parents',
					}"
				/>
				<sidebar-item
					title="Counseling"
					:link="{
						name: 'Counseling',
						icon: 'fa fa-list-alt text-success',
						path: '/counseling',
					}"
				/>
				<sidebar-item
					title="Demo Class"
					:link="{
						name: 'Demo Class',
						icon: 'fa fa-binoculars text-success',
						path: '/demo-class',
					}"
				/>
				<sidebar-item
					title="Subjects"
					:link="{
						name: 'Subjects',
						icon: 'fa fa-cubes text-success',
						path: '/subjects',
					}"
				/>
				<sidebar-item
					title="Admins"
					:link="{
						name: 'Admins',
						icon: 'fa fa-user text-white',
						path: '/users',
					}"
				/>
			</template>
		</side-bar>
		<div class="main-content" :data="sidebarBackground">
			<dashboard-navbar></dashboard-navbar>

			<div @click="toggleSidebar">
				<loader />
				<fade-transition
					:duration="200"
					origin="center top"
					mode="out-in"
				>
					<router-view></router-view>
				</fade-transition>
			</div>
		</div>
	</div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import { FadeTransition } from "vue2-transitions";

export default {
	components: {
		DashboardNavbar,
		FadeTransition,
	},
	data() {
		return {
			sidebarBackground: "vue", //vue|blue|orange|green|red|primary
		};
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
	},
};
</script>
<style></style>
