<template>
	<div>
		<div class="pb-7 pt-7 pt-lg-8 d-flex align-items-center">
			<span class="mask bg-white"></span>
			<div class="container-fluid align-items-center">
				<div class="row d-flex justify-content-between">
					<div class="col-8 col-sm-10">
						<h1 class="text-orange">Hey {{ userData.name }}</h1>
						<p class="pl-2 mt-0 mb-0">Your profile information</p>
						<!-- <a href="#!" class="btn btn-info">Edit profile</a> -->
					</div>
					<!-- <div class="col ml--3">
						<base-button
							@click.prevent="adminAdd = true"
							style="
								border-radius: 15px;
								background-color: #fe4a55;
							"
							class="hidden py-1 mt-3 px-3 px-sm-4"
							type="primary"
							icon="pl-0 ni ni-single-02"
							size="sm"
							>Add</base-button
						>
					</div> -->
				</div>

				<div class="container-fluid mt-3">
					<div class="row">
						<div class="col">
							<card shadow type="secondary">
								<div slot="header" class="bg-white border-0">
									<div class="row align-items-center">
										<div
											class="col-12 d-flex flex-column flex-sm-row justify-content-between"
										>
											<div class="col-6 col-sm-2">
												<h3 class="mb-0">My account</h3>
											</div>
											<div class="col-12 col-sm-6 mt-3 mt-sm-0">
												<base-button
													@click.prevent="
														profileEdit =
															!profileEdit
													"
													v-if="!profileEdit"
													style="
														border-radius: 20px;
														background-color: #2f80ed;
													"
													class="hidden col-12 col-sm-3 py-1 px-4"
													type="primary"
													size="sm"
													>Edit</base-button
												>
												<base-button
													@click.prevent="
														profileEdit =
															!profileEdit
													"
													v-else
													style="
														border-radius: 20px;
														background-color: #ec0c38;
													"
													class="hidden py-1 px-4"
													type="primary"
													size="sm"
													>Cancel</base-button
												>
											</div>
											<div class="col-12 col-sm-3 mt-3 mt-sm-0">
												<base-button
													@click.prevent="
														editPassword
													"
													style="border-radius: 20px"
													class="hidden col-12 col-sm-10 py-1 px-4"
													type="primary"
													outline
													size="sm"
													>Edit Password</base-button
												>
											</div>
										</div>
									</div>
								</div>
								<template>
									<form @submit.prevent>
										<h6
											class="heading-small text-muted mb-4"
										>
											User information
										</h6>
										<div class="pl-lg-4">
											<div class="row">
												<div class="col-lg-6">
													<base-input
														alternative=""
														label="Username"
														input-classes="form-control-alternative"
														v-model="
															$store.state
																.loginData
																.username
														"
														:disabled="true"
													/>
												</div>
												<div class="col-lg-6">
													<base-input
														alternative=""
														label="User ID"
														input-classes="form-control-alternative"
														v-model="
															$store.state
																.loginData
																.userID
														"
														:disabled="true"
													/>
												</div>
											</div>
											<div class="row">
												<div class="col-lg-6">
													<base-input
														alternative=""
														label="Name"
														input-classes="form-control-alternative"
														v-model="userData.name"
														:disabled="!profileEdit"
													/>
												</div>
												<div class="col-lg-6">
													<label
														for="gender"
														class="text-dark"
														>Gender</label
													>
													<select
														class="form-control"
														name="gender"
														id="gender"
														v-model="
															userData.gender
														"
														:disabled="!profileEdit"
													>
														<option value="male">
															Male
														</option>
														<option value="female">
															Female
														</option>
														<option value="other">
															Other
														</option>
													</select>
												</div>
											</div>
										</div>
										<hr class="my-4" />
										<h6
											class="heading-small text-muted mb-4"
										>
											Contact information
										</h6>
										<div class="pl-lg-4">
											<div class="row">
												<div class="col-md-4">
													<label
														class="text-dark"
														for="state"
														>State</label
													>
													<select
														class="form-control text-bold"
														v-model="
															userData
																.district_and_state
																.state_id
														"
														:disabled="!profileEdit"
														id="state"
													>
														<option
															v-for="(
																state, idx
															) in states"
															:key="idx"
															:value="state.id"
														>
															{{ state.state }}
														</option>
													</select>
												</div>
												<div
													v-if="!profileEdit"
													class="col-md-4"
												>
													<base-input
														alternative=""
														label="District"
														input-classes="form-control-alternative"
														v-model="
															userData
																.district_and_state
																.district_name
														"
														:disabled="!profileEdit"
													/>
												</div>
												<div v-else class="col-md-4">
													<label for="district"
														>District</label
													>
													<select
														class="form-control text-dark"
														id="district"
														@change="
															onChange($event)
														"
													>
														<option
															:selected="true"
															:value="
																userData
																	.district_and_state
																	.district
															"
														>
															{{
																userData
																	.district_and_state
																	.district_name
															}}
														</option>
														<option
															v-for="(
																district, idx
															) in districts"
															:key="idx"
															:value="district.id"
														>
															{{
																district.district
															}}
														</option>
													</select>
												</div>
												<div class="col-md-4">
													<base-input
														alternative=""
														label="Location"
														input-classes="form-control-alternative"
														v-model="
															userData.location
														"
														:disabled="!profileEdit"
													/>
												</div>
											</div>
											<div class="row">
												<!-- <div class="col-lg-4">
											<base-input
												alternative=""
												label="Email ID"
												input-classes="form-control-alternative"
												v-model="$store.state.userData.email"
												:disabled="true"
											/>
										</div> -->
												<div class="col-lg-6">
													<base-input
														alternative=""
														label="Mobile number"
														input-classes="form-control-alternative"
														v-model="
															userData.mobile
														"
														:disabled="!profileEdit"
													/>
												</div>
												<div class="col-lg-6">
													<base-input
														alternative=""
														label="Whatsapp number"
														input-classes="form-control-alternative"
														v-model="
															userData.whatsapp_number
														"
														:disabled="!profileEdit"
													/>
												</div>
											</div>

											<div
												v-if="profileEdit"
												class="pt-6 row d-flex justify-content-center flex-nowrap"
											>
												<base-button
													@click.prevent="profileSave"
													style="
														border-radius: 20px;
														width: 140px;
														height: 46px;
														background-color: #f35d94;
													"
													class="hidden py-1 px-4"
													type="primary"
													>Save</base-button
												>
											</div>
										</div>
									</form>
								</template>
							</card>
						</div>
					</div>

					<modal
						modal-classes="modal-dialog-centered modal-sm"
						:show="password"
						@close="password = !password"
					>
						<template v-slot:header>
							<h4 class="modal-title">Change your password</h4>
						</template>
						<div class="form-group row d-flex">
							<label
								for="Admin_add"
								class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>
								Password</label
							>

							<div class="col-sm-6">
								<input
									type="password"
									style="height: 35px; width: 200px"
									class="form-control"
									id="Admin_add"
									v-model="tempPassword"
								/>
							</div>
						</div>
						<div class="form-group row d-lg-flex">
							<label
								for="Admin"
								class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>
								Confirm</label
							>

							<div class="col-sm-6">
								<input
									type="password"
									style="height: 35px; width: 200px"
									class="form-control"
									id="Admin"
									v-model="userData.password"
								/>
							</div>
						</div>

						<template v-slot:footer>
							<div class="text-center">
								<base-button
									@click.prevent="passwordSave"
									size="sm"
									type="primary"
									>Submit</base-button
								>
							</div>
						</template>
					</modal>
					<!-- admin add modal -->
					<!-- <modal
						:show="adminAdd"
						@close="closeAddModal"
						body-classes="p-0"
						modal-classes="modal-dialog-centered modal-sm"
					>
						<card
							type="secondary"
							shadow
							header-classes="bg-white"
							body-classes="px-lg-5 "
							class="border-0"
						>
							<template v-slot:header>
								<div class="text-muted text-center mb-1">
									<h3 class="text-muted">
										Create new account
									</h3>
								</div>
							</template>
							<form role="form">
								<base-input
									formClasses="input-group-alternative mb-3"
									type="text"
									placeholder="Name"
									addon-left-icon="ni ni-circle-08"
									v-model="newUser.name"
								>
								</base-input>
								<base-input
									formClasses="input-group-alternative mb-3"
									placeholder="Email"
									addon-left-icon="ni ni-email-83"
									v-model="newUser.email"
								>
								</base-input>
								<base-input
									formClasses="input-group-alternative mb-3"
									type="number"
									placeholder="Mobile"
									addon-left-icon="ni ni-mobile-button"
									v-model="newUser.mobile"
								>
								</base-input>
								<base-input
									formClasses="input-group-alternative mb-3"
									type="text"
									placeholder="Username"
									addon-left-icon="ni ni-single-02"
									v-model="newUser.username"
								>
								</base-input>
								<base-input
									formClasses="input-group-alternative mb-3"
									v-model="newUser.password"
									type="password"
									placeholder="Password"
									addon-left-icon="ni ni-lock-circle-open"
								>
								</base-input>

								<div class="text-center">
									<base-button
										@click.prevent="adminAdd = !adminAdd"
										size="sm"
										type="primary"
										outline
										class="my-2"
										>Cancel</base-button
									>
									<base-button
										@click.prevent="addNewUser"
										size="sm"
										type="success"
										class="my-2"
										>Create</base-button
									>
								</div>
							</form>
						</card>
					</modal> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "user-profile",
	data() {
		return {
			profileEdit: false,
			password: false,
			adminAdd: false,
			tempPassword: null,
			userData: {
				name: "",
				gender: "",
				district_and_state: {
					district_name: "",
					district: null,
					state_id: null,
				},
				location: "",
				mobile: "",
				whatsapp_number: "",
				password: null,
			},
			newUser: {
				name: "",
				email: "",
				mobile: "",
				username: "",
				password: "",
			},
		};
	},
	computed: {
		profileData() {
			return this.$store.state.profileData.data;
		},
		districts() {
			return this.$store.state.LocationData.districts;
		},
		states() {
			return this.$store.state.LocationData.states;
		},
	},
	methods: {
		profileSave() {
			var error = undefined;
			if (this.userData.name.trim() == "") {
				error = "Name is required";
			} else if (this.userData.mobile == "") {
				error = "Mobile number is required";
			} else if (this.userData.mobile.length > 12) {
				error = "Enter valid mobile number";
			} else if (this.userData.whatsapp_number == "") {
				error = "Whatsapp number is required";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.$store
				.dispatch("setAdminProfile", {
					userData: this.userData,
				})
				.then(() => this.checkProfileData())
				.catch((err) => {
					this.$notify({
						title: "Failed to edit profile",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
			this.profileEdit = false;
		},
		passwordSave() {
			var error = undefined;
			if (this.userData.password == null) {
				error = "Enter valid password";
			} else if (this.userData.password != this.tempPassword) {
				error = "The passwords entered don't match each other";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.password = false;
		},
		addNewUser() {
			var error = undefined;
			if (this.newUser.name.trim() == "") {
				error = "Name is required";
			} else if (this.newUser.email == "") {
				error = "Email is required";
			} else if (this.newUser.mobile == "") {
				error = "Mobile number is required";
			} else if (this.newUser.username == "") {
				error = "Username is required";
			} else if (this.newUser.password == "") {
				error = "Password is required";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.$store
				.dispatch("setNewUser", {
					Data: this.newUser,
				})
				.then(() => this.closeAddModal())
				.catch((err) => {
					this.$notify({
						title: "Failed to add new user",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		editPassword() {
			this.password = !this.password;
			this.profileEdit = !this.profileEdit;
		},
		closeAddModal() {
			this.adminAdd = !this.adminAdd;
			this.fetchAdminProfile();
		},
		fetchAdminProfile() {
			this.$store.dispatch("fetchAdminProfile").catch((err) => {
				this.$notify({
					title: "Failed to fetch user details",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		checkProfileData() {
			this.$store
				.dispatch("fetchAdminProfile")
				.then(() => {
					this.prepareData();
				})
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch user details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		prepareData() {
			this.userData = JSON.parse(JSON.stringify(this.profileData));
		},
		fetchDistrictList() {
			this.$store
				.dispatch(
					"fetchDistrictList",
					this.userData.district_and_state.state_id
				)
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchStateList() {
			this.$store.dispatch("fetchStateList").catch((err) => {
				this.$notify({
					title: "Failed to fetch state",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		onChange(event) {
			this.userData.district_and_state.district = event.target.value;
		},
	},
	watch: {
		"userData.district_and_state.state_id"() {
			this.fetchDistrictList();
		},
	},
	mounted() {
		this.checkProfileData();
		this.fetchDistrictList();
		this.fetchStateList();
	},
};
</script>
